<template>
  <kdx-content-bar ref="content_bar" :loading="loading">
    <div class="content">
      <kdx-form-title content="基本信息"></kdx-form-title>
      <Form class="form" :label-width="120" :model="model" ref="form" @submit.native.prevent :rules="rules">
        <form-item label="个人头像：">
          <Avatar v-model="model.avatar" @success="changeAvatar" />
          <kdx-hint-text>建议尺寸：200x200px</kdx-hint-text>
        </form-item>
        <FormItem label="联系人：" prop="linkman">
          <Input v-model="model.linkman" type="text" maxlength="30" show-word-limit class="width-430 limit-input"
            placeholder="请输入联系人" />
        </FormItem>
        <FormItem label="联系电话：" prop="tel">
          <Input v-model="model.tel" type="text" maxlength="30" show-word-limit class="width-430 limit-input"
            placeholder="请输入手机/座机号码" />
        </FormItem>
        <FormItem label="联系地址：" prop="addr">
          <Input v-model="model.addr" type="text" maxlength="60" show-word-limit class="width-430 limit-input"
            placeholder="请输入联系地址" />
        </FormItem>
        <FormItem label="公司名称：">
          <Input v-model="model.comName" type="text" maxlength="60" show-word-limit class="width-430 limit-input"
            placeholder="请输入公司名称" />
        </FormItem>
      </Form>

    </div>
    <template #btn>
      <Button class="default-long" @click="handleSave">保存</Button>
    </template>
  </kdx-content-bar>
</template>

<script>
import { throttle } from '@/helpers/common';
import Avatar from '../components/Avatar.vue';
export default {
  components: {
    Avatar,
  },
  data() {
    return {
      loading: false,
      model: {
        avatar: '',
        tel: '',
        linkman: '',
        addr: '',
        comName: ''
      },
      rules: {
        tel: [
          { required: true, message: '请输入联系电话', trigger: 'blur' }
        ],
        linkman: [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
        addr: [
          { required: true, message: '请输入联系地址', trigger: 'blur' }
        ],
      }
    };
  },
  async created() {
    await this.$store.dispatch('alone/getUserInfo');
    this.getProfile();
  },
  methods: {
    async changeAvatar(res) {
      await this.$api.user.changeAvatar({ arvatarId: res.id }, { toast: 1 });
    },
    async getProfile() {
      const { avatar_url, tel, linkman, addr, comName } = this.$store.state.alone.user;
      this.model = {
        avatar: avatar_url,
        tel, linkman, addr, comName
      };
    },
    handleSave: throttle(function () {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return;
        }
        this.loading = true;
        const { tel, linkman, addr, comName } = this.model;
        await this.$api.user.changeinfo({ tel, linkman, addr, comName }, { toast: 1 }).finally(() => {
          this.loading = false;
        });
      });
    }, 400)
  }
};
</script>

<style lang="scss" scoped>
.content {
  border-radius: 2px;
  margin: 20px auto 0;
  background-color: #ffffff;
  padding: 20px 40px;
  min-height: calc(100% - 20px) !important;
}

/deep/ .ivu-tabs-bar {
  margin-bottom: 40px;
  padding: 0 30px;

  .ivu-tabs-tab {
    padding: 12px 30px;
    @include font-18-25-bold;

    .icon-tishi-prompt {
      position: absolute;
      right: 10px;
      color: $danger-color;
      font-size: 18px;
    }
  }
}

/deep/ .ivu-form-item-label {
  padding-right: 10px !important;
}
</style>