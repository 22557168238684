<template>
    <label class="avatar" for="file">
        <!-- <div class="container un-choose" v-if="!value">
            <i class="iconfont icon-tianjiatupian add-icon" />
        </div> -->
        <div class="container choose">
            <img :src="getImgPath" class="avatar-img" />
            <div class="replace-btn">替换</div>
        </div>
        <input id="file" type="file" style="display: none" ref="upload_input" @change="changeFile" name="file" />
    </label>
</template>

<script>
export default {
    props: {
        value: {
            type: [String]
        },
        default: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        getImgPath() {
            return this.value;
        },
        maxSize() {
            return 1024 * 5;
        },

    },
    created() {

    },
    methods: {
        /* 保留两位小数
            * num 传入数值
            * return 返回两位小数值
            */
        toFixed(num) {
            let times = Math.pow(10, 2);
            let des = num * times + 0.5;
            des = parseInt(des, 10) / times;
            return des + '';
        },
        // 图片选择
        changeFile(e) {
            if (e) {
                let file = e.target.files[0];
                if (file.size > this.maxSize * 1024) {
                    this.$Message.error(`图片应选择小于${this.toFixed(this.maxSize / 1024)}M的`);
                    return;
                }
                let type = ['png', 'jpg'].join('|');
                let reg2 = eval("/^(\\s|\\S)+(" + type + ")+$/");
                if (!reg2.test(file.name.toLowerCase())) {
                    this.$Message.error('请选择正确的格式的图片');
                    return;
                }

                this.upload(file);

            }
            this.$refs['upload_input'].value = '';
        },
        async upload(file) {
            const result = await this.$api.other.addfile({ file });
            this.$emit('input', result.path);
            this.$emit('success', result);
        }
    }
};
</script>

<style lang="scss" scoped>
.avatar,
.container,
.un-choose,
.choose,
.avatar-img {
    width: 70px;
    height: 70px;
}

.avatar {
    display: block;
}

.avatar-img {
    display: block;
}

.container {
    position: relative;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #e9edef;

    &.choose {
        position: relative;
        overflow: hidden;

        .replace-btn {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 20px;
            z-index: 1;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: #ffffff;
            background: rgba(0, 0, 0, 0.7);
        }
    }

    &.un-choose {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px dashed #e9edef;

        .add-icon {
            font-size: 28px;
            color: #1154ff;
        }
    }
}
</style>